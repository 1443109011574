import Glide, { Controls, Breakpoints, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'
import 'fslightbox';

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.slide').forEach((slider) => {
		new Glide(slider, {
			type: 'carousel',
			perTouch: slider.dataset.perView,
			perView: slider.dataset.perView,
			autoplay: Number(slider.dataset.autoplay) || 0,
			breakpoints: { 768: { perTouch: 1, perView: 1 } }
		}).mount({ Controls, Breakpoints, Swipe, Autoplay });

		const slides = slider.querySelectorAll('.glide__slide:not(.glide__slide--clone)');
		const counter = slider.querySelector('.counter');
		let counterNumber = counter.innerHTML;

		const updateIndex = () => {
			setTimeout(() => {
				slides.forEach((el, index) => {
					if (el.classList.contains('glide__slide--active')) {
						counterNumber = index + 1;
					}
				});
				counter.innerHTML = counterNumber;
			}, 400);
		};

		slider.addEventListener('mouseup', updateIndex);
		slider.addEventListener('touchend', updateIndex);
		slider.querySelector('.slide__arrow--left').addEventListener('click', updateIndex);
		slider.querySelector('.slide__arrow--right').addEventListener('click', updateIndex);
	});
});
